import React from 'react'

import Layout from 'components/Layout/Layout';
import Error from 'components/Error/Error';
import SEO from 'components/seo';

const ErrorPage = ({location}) => (
  <Layout displayProgressBar={false}>
    <SEO title="Error" />
    <Error errorCode={location.state ? location.state.errorCode : 100} alerts={location.state ? location.state.alerts : null} />
  </Layout>
)

export default ErrorPage;
